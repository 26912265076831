// AOS
import AOS from 'aos';
// アニメーションの設定
AOS.init({
    delay: 0,
    offset: 200,
    once: true,
    duration: 800,
});


const trigger = document.querySelectorAll(".recipe_item[data-target]")

// dialogの発火イベント
trigger.forEach(item => {
    item.addEventListener("click", event => {
        const target = event.currentTarget.dataset.target;
        const targetEl = document.getElementById(target);
        targetEl.showModal();
    })
})

// dialogのcloseイベント
const btnClose = document.querySelectorAll('.btn_close');
btnClose.forEach(btn => {
    btn.addEventListener( 'click', event => {
        event.currentTarget.parentNode.parentNode.close();
    }, false );
})

const dialogs = document.querySelectorAll('dialog');
dialogs.forEach(dialog => {
    dialog.addEventListener( 'click', event => {
        event.currentTarget.close();
    }, false );
    
    const contents = dialog.querySelector( '.dialog_inner' );
    contents.addEventListener( 'click', event => {
        event.stopPropagation();
    }, false );
})


